import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './Gallery.scss'
import { connect } from "react-redux";
import { changeItems } from '../actions/automationAction'
import ShortUniqueId from 'short-unique-id';
import GalleryNew from './GalleryNew'
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

interface Props {
    item: any,
    allItems: any,
    addOrUpdateFlow: any,
    changeItems: (items: any[]) => any,
    index: number,
    flows: any

}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result

}


class Gallery extends React.Component<Props, { items: any[] }>{
    constructor(props: Props) {
        super(props)
        this.state = {
            items: this.props.item.generic
        }
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const items: any = reorder(this.props.item.generic, result.source.index, result.destination.index)

        let allItems: any = [...this.props.allItems]
        let newItem = {
            id: this.props.item.id,
            generic: items
        }
        allItems[this.props.index] = newItem
        this.props.changeItems(allItems)
        this.props.addOrUpdateFlow(allItems)
        console.log(items)
    }

    addGallery = () => {
        if (this.props.item.generic.length > 0 && this.props.item.generic.length < 10) {
            let uid: any = new ShortUniqueId()
            let items: any = [...this.props.item.generic]
            items.push({
                id: uid.randomUUID(12).toUpperCase(),
                title: null,
                image_url: null,
                subtitle: null,
            })
            let allItems: any = [...this.props.allItems]
            let newItem = {
                id: this.props.item.id,
                generic: items
            }
            allItems[this.props.index] = newItem
            this.props.addOrUpdateFlow(allItems)
            console.log(this.props.allItems)
        }
    }

    render() {
        return (
            <React.Fragment>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div className="hover_gallery_icon">
                        <div className="delete_icon hover_delete">
                            <i className="fa fa-trash-o" aria-hidden="true" onClick={() => this.props.addOrUpdateFlow(this.props.allItems.filter(item => item.id !== this.props.item.id))}></i>
                        </div>
                        <div className="main_box_gallery gallery_main_slider" id={this.props.item.id}>
                            <Droppable droppableId={this.props.item.id} direction="horizontal" type={this.props.item.id}>
                                {(provided, snapshot) => (
                                    <div className="D-flex" ref={provided.innerRef}>
                                        {[...this.props.item.generic].map((item: any, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div>
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            <span className="drag_resizer"
                                                                {...provided.dragHandleProps}
                                                                style={{ display: 'inline-block', margin: '0 10px', cursor: 'ew-resize', fontSize: 20}}>
                                                                ↔
                                                            </span>
                                                            <GalleryNew flows={this.props.flows} item={item} index={index} parentIndex={this.props.index} addOrUpdateFlow={this.props.addOrUpdateFlow} />

                                                        </div>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        {this.props.item.generic.length > 0 && this.props.item.generic.length < 10 &&
                                            <div className="box_newadd" id="msgbox_add" onClick={() => this.addGallery()}>
                                                <i className="fa fa-plus " aria-hidden="true"></i>
                                            </div>
                                        }

                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </DragDropContext>
                <ToastContainer />
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.currentUserToken,
    allItems: state.automation.items,
});

export default connect(mapStateToProps, { changeItems })(Gallery);
